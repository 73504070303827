import * as React from "react";
import { InboxIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { Link } from "gatsby";

export interface ImageFeatureSectionProps {
  link?: {
    name: string;
    to: string;
  };
  children: React.ReactNode;
  title: string;
  description: string;
  alignImage?: "left" | "right";
}

const ImageFeatureSection = ({
  link,
  children,
  title,
  description,
  alignImage = "left",
}: ImageFeatureSectionProps) => {
  return (
    <div className="relative overflow-hidden rounded-md bg-slate-200 pt-16 pb-16">
      {alignImage === "right" && (
        <div className="relative">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                {title}
              </h2>
              <div className="mt-4 text-lg text-gray-500">
                <p>{description}</p>
                {link && (
                  <div className="mt-6">
                    <Link
                      to={link.to}
                      className="text-base font-medium text-indigo-500"
                    >
                      {link.name}&nbsp;&rarr;
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                <div className="overflow-hidden rounded-md shadow-md">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {alignImage === "left" && (
        <div className="relative">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
            <div className="mx-auto max-w-xl px-4 sm:px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                {title}
              </h2>
              <div className="mt-4 text-lg text-gray-500">
                <p>{description}</p>
                {link && (
                  <div className="mt-6">
                    <Link
                      to={link.to}
                      className="text-base font-medium text-indigo-500"
                    >
                      {link.name}&nbsp;&rarr;
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
              <div className="-ml-48 pr-4 sm:pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                <div className="overflow-hidden rounded-md shadow-md">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageFeatureSection;
