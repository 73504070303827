import * as React from "react";
import { HeadFC, Link, navigate } from "gatsby";
import { CustomHead } from "../components/shell/CustomHead";
import Shell from "../components/shell/Shell";
import {
  BanknotesIcon,
  BuildingLibraryIcon,
  IdentificationIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import ImageFeatureSection from "../components/shared/sections/ImageFeatureSection";
import { StaticImage } from "gatsby-plugin-image";
import CreateLoanForm from "../components/forms/CreateLoanForm";
import { toast } from "react-toastify";

const pageDescription =
  "Funding your business to success through our Project Equity Finance, Invoice Discounting, Debtor Financing, and Purchase Order Finance services.";

const IndexPage = () => {
  const [amount, setAmount] = React.useState<string | undefined>("500");

  const submitPersonalLoanForm = (isValid: boolean) => {
    if (isValid) {
      navigate(`/dashboard/new/personal-loan?initialAmount=${amount ?? "500"}`);
    } else {
      toast.warn(
        "The loan amount is invalid, please make sure its between R 500.00 and R 5,000.00 and try again."
      );
    }
  };

  return (
    <Shell>
      <div className="overflow-hidden">
        <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
            <div>
              <h2 className="text-lg font-semibold text-indigo-600">
                What you'll need to
              </h2>
              <h3 className="mt-2 text-4xl font-bold leading-8 tracking-tight text-gray-900 sm:text-5xl">
                Apply for a Loan
              </h3>
              <ul className="mt-12 space-y-6 text-base text-gray-500">
                <li className="flex items-center">
                  <PhoneIcon
                    className="h-6 w-6 flex-shrink-0 rounded-full bg-white p-1 text-indigo-500 shadow-md"
                    aria-hidden="true"
                  />
                  <span className="ml-3">A cellphone number</span>
                </li>
                <li className="flex items-center">
                  <IdentificationIcon
                    className="h-6 w-6 flex-shrink-0 rounded-full bg-white p-1 text-indigo-500 shadow-md"
                    aria-hidden="true"
                  />
                  <span className="ml-3">A South African ID number</span>
                </li>
                <li className="flex items-center">
                  <BuildingLibraryIcon
                    className="h-6 w-6 flex-shrink-0 rounded-full bg-white p-1 text-indigo-500 shadow-md"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Bank account details</span>
                </li>
                <li className="flex items-center">
                  <BanknotesIcon
                    className="h-6 w-6 flex-shrink-0 rounded-full bg-white p-1 text-indigo-500 shadow-md"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Most recent proof of income</span>
                </li>
              </ul>
              <p className="mt-12 text-2xl font-bold">What can you get?</p>
              <p className="mt-2 text-base text-gray-500">
                New customers can apply for up to R2,500 with 35 days to pay.
                Existing customers up to R5,000 with 35 days to pay.
              </p>
            </div>
            <div className="overflow-hidden rounded-md bg-white shadow-md">
              <div className="bg-indigo-600 p-4 shadow-md md:p-6 lg:p-8">
                <p className="flex justify-center text-2xl text-indigo-50">
                  How much do you need?
                </p>
              </div>
              <div className="p-4 md:p-6 lg:p-8">
                <CreateLoanForm
                  label="Next"
                  amount={amount}
                  minLabel="R 500"
                  maxLabel="R 5,000"
                  minimumAmount={500}
                  maximumAmount={5000}
                  onAmountChanged={setAmount}
                  onButtonClick={submitPersonalLoanForm}
                />
              </div>
            </div>
          </div>
          <div className="mt-24">
            <ImageFeatureSection
              alignImage="right"
              title="A Wide Range Of Financial Services"
              description="An authorized financial services and registered credit provider established in 2012 initially as a goods and services provider, we have honed our understanding and belief in the power of small business, with SMEs responsible for over 40% of South Africa's GDP and contributing to over 60% of employment."
              link={{ name: "View more services", to: "/services" }}
            >
              <StaticImage
                quality={100}
                className="w-full"
                src="../images/home-1.png"
                alt="One man sitting down, viewing financial charts on a tablet on his lap"
              />
            </ImageFeatureSection>
          </div>
          <div className="mt-12">
            <ImageFeatureSection
              title="Why Choose Us?"
              description={`Our previous experience as contractors gives us a deep understanding of our business clients, often allowing us to anticipate their needs. Our dedication to excellent client service is evident in our simplified application process which minimizes laborious admin requests. Our short-term personal loans are flexible with a quick and simple online application process that is transparent. There are no hidden charges and the Personal Loan Calculator provides a detailed breakdown of the monthly installment repayments.`}
              link={{ name: "Learn more about Nora Finance", to: "/about" }}
            >
              <StaticImage
                quality={100}
                className="w-full"
                src="../images/home-2.png"
                alt="African woman smiling on a portrait"
              />
            </ImageFeatureSection>
          </div>
          <div className="mt-12">
            <ImageFeatureSection
              alignImage="right"
              title="Get to know about insurance."
              description="Providing insurance solutions for personal and commercial markets. Comprehensive intermediary services are also offered to our clients for both long and short term insurance."
              link={{ name: "Explore our insurance range", to: "/insurances" }}
            >
              <StaticImage
                quality={100}
                className="w-full"
                src="../images/insurances-2.jpg"
                alt="A pen and an insurance checkbox with a red tick"
              />
            </ImageFeatureSection>
          </div>
        </div>
      </div>
    </Shell>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <CustomHead title="Home" description={pageDescription} />
);
